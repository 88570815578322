import seo from "./seo.svg";
import app from "./app.svg";
import digital from "./digital.svg";
import research from "./research.svg";
import branding from "./branding.svg";
import plus from "./plus.svg"

const content=[{
    title:"WEBSITE DESIGNING",
    description:"Let us help you get your business online with a beautifully crafted and desgined website tailored to your needs and suited to your budget.",
    image:seo,
    link:"/webdev"
},
{
    title:"APP DEVELOPMENT",
    description:"Get a beautifully crafted app for your idea or existing business and take it to new heights without emptying your pockets.",
    image:app,
    link:"/appdev"
},
{
    title:"INTERNET OF THINGS",
    description:"Boost your business with the power of IOT with our end to end IOT solutions making lifes better and smarter..",
    image:digital,
    link:"/iotdev"
},
{
    title:"SEO",
    description:"Get a strong and powerful online presence and better visibility. Always stay ahead of your competitors.",
    image:research,
    link:"/seodev"
},
{
    title:"BRANDING",
    description:"Make your business a brand with the most suitable logo to branded mechandise and everything in between." ,
    image:branding,
    link:"/brandingdev"
},
// {
//     title:"COMING SOON",
//     description:"Make your business a brand with the most suitable logo to branded mechandise and everything in between.",
//     image:plus
// }
];
export default content;