import icon1 from './visit_2.jpg';
import logo from './logo.png'
import icon2 from './visit_3.jpg';





const Product = [
    {
        title:"JUGADU RED" , 
        desc:"Elevate your Ideas beyond the ordinary",
        banner:icon1,
        link:"/jugaduRed"

    } , 

    {
        title:"JUGADU BOOST" , 
        desc:"Take Your Business to the next level with Boost",
        banner:icon2,
        link:"/jugaduBoost"
    }
];

export default Product;